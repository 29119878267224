import { changeCurrency } from "helpers/helpersFunc";
import { useWindowSize } from "hooks";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { getDomainList } from "services/allServices";
import { useGlobalStore } from "store/GlobalStore";

function HomeDomainSearch() {
  const { t } = useTranslation(["header", "nav", "homapage"]);
  const windowSize = useWindowSize();

  const [currencyType, currencyRates] = useGlobalStore((state) => [state.currencyType, state.currencyRates]);
  const [filteredDomainList, setFilteredDomainList] = useState();
  const [domainName, setDomainName] = useState("");
  const router = useRouter();
  const calculatePrice = (p, currency) => {
    let newPrice = changeCurrency(currencyRates, currencyType, parseFloat(p?.toFixed(2)), currency);
    let price = newPrice;
    let splitedPrice = price.split(".");
    return splitedPrice;
  };

  const onGoDomainPage = () => {
    if (domainName !== "") {
      router.push({
        pathname: t("nav:domainfind"),
        query: { homeDomainName: domainName }
      });
    }
  };

  const handleKeypress = e => {
    if (e.charCode === 13) { //enter tuşuna basınca çalışması için
      onGoDomainPage();
    }
  };

  const onGetDomainList = () => {
    getDomainList().then(res => {
      let filterList = [];
      res.map(item => {
        if (item.bring_forward) {
          filterList.push(item);
        }
      });
      setFilteredDomainList(filterList);
    }).catch(err => {
      console.log(err);
    });

  };

  useEffect(() => {
    onGetDomainList();
  }, []);

  return (
    <div className="w-full mt-10 mb-8 pt-7 pb-7">
      <div className="bg-homebg1">
        <div className="container mx-auto ">
          <div className="grid grid-cols-12 lg:h-72 2xl:h-80">
            <div className="col-span-12 p-8 text-center items-right lg:col-span-5">
              <div className="flex flex-col">
                <div className="lg:float-right lg:mr-6">
                  <svg
                    className="mx-auto mb-4"
                    width="120"
                    height="120"
                    viewBox="0 0 120 120"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100.414 75.9992L106.424 79.6042C106.795 79.8262 107.102 80.1405 107.315 80.5164C107.528 80.8924 107.64 81.3171 107.64 81.7493C107.64 82.1814 107.528 82.6061 107.315 82.9821C107.102 83.358 106.795 83.6723 106.424 83.8942L62.5744 110.204C61.7968 110.671 60.9066 110.918 59.9994 110.918C59.0922 110.918 58.2021 110.671 57.4244 110.204L13.5744 83.8942C13.2037 83.6723 12.8968 83.358 12.6837 82.9821C12.4706 82.6061 12.3586 82.1814 12.3586 81.7493C12.3586 81.3171 12.4706 80.8924 12.6837 80.5164C12.8968 80.1405 13.2037 79.8262 13.5744 79.6042L19.5844 75.9992L59.9994 100.249L100.414 75.9992V75.9992ZM100.414 52.4992L106.424 56.1042C106.795 56.3262 107.102 56.6405 107.315 57.0164C107.528 57.3924 107.64 57.8171 107.64 58.2492C107.64 58.6814 107.528 59.1061 107.315 59.4821C107.102 59.858 106.795 60.1723 106.424 60.3943L59.9994 88.2493L13.5744 60.3943C13.2037 60.1723 12.8968 59.858 12.6837 59.4821C12.4706 59.1061 12.3586 58.6814 12.3586 58.2492C12.3586 57.8171 12.4706 57.3924 12.6837 57.0164C12.8968 56.6405 13.2037 56.3262 13.5744 56.1042L19.5844 52.4992L59.9994 76.7493L100.414 52.4992ZM62.5694 6.54425L106.424 32.8542C106.795 33.0762 107.102 33.3905 107.315 33.7664C107.528 34.1424 107.64 34.5671 107.64 34.9992C107.64 35.4314 107.528 35.8561 107.315 36.2321C107.102 36.608 106.795 36.9223 106.424 37.1442L59.9994 64.9992L13.5744 37.1442C13.2037 36.9223 12.8968 36.608 12.6837 36.2321C12.4706 35.8561 12.3586 35.4314 12.3586 34.9992C12.3586 34.5671 12.4706 34.1424 12.6837 33.7664C12.8968 33.3905 13.2037 33.0762 13.5744 32.8542L57.4244 6.54425C58.2021 6.07703 59.0922 5.8302 59.9994 5.8302C60.9066 5.8302 61.7968 6.07703 62.5744 6.54425H62.5694ZM59.9994 16.6592L29.4344 34.9992L59.9994 53.3393L90.5644 34.9992L59.9994 16.6592Z"
                      fill="#FFDD00"
                    />
                  </svg>
                  <h2 className="text-2xl font-bold text-makdosYellow">
                    {t("homepage:domainSearchLeft")}
                  </h2>
                  <h2 className="text-2xl font-bold text-makdosYellow">
                    {t("homepage:domainSearchLeft2")}
                  </h2>
                </div>
              </div>
            </div>

            <div className="z-10 col-span-12 px-8 py-8 text-center lg:rounded-md bg-makdosYellow 2xl:h-96 lg:h-80 lg:col-span-7 lg:px-12 lg:-mt-4 2xl:-mt-8">
              <div
                className="flex items-center justify-center w-full h-full bg-no-repeat md:bg-mapbg md:bg-center md:bg-cover">
                <div className="w-full lg:px-16">
                  <p className="pb-2 text-md text-titleColor md-pb-0">
                    {t("homepage:domainSearchRight")}
                  </p>
                  <h2 className="text-5xl font-semibold text-titleColor">
                    {t("homepage:domainSearchRight2")}
                  </h2>
                  <p className="pt-2 text-md text-titleColor md-pt-0">
                    {t("homepage:domainSearchRight3")}
                  </p>
                  <div className="flex items-center justify-center mt-5 mb-5 shadow-lg">
                    <input
                      className="w-full p-4 rounded-l-lg focus:outline-none"
                      type="text"
                      placeholder={t("homepage:dreamdomain")}
                      value={domainName} onChange={(e) => setDomainName(e.target.value)}
                      onKeyPress={(e) => handleKeypress(e)}
                    />
                    <button id="search-button"  aria-label="search" name="search-button" type="button" onClick={() => onGoDomainPage()}
                            className="flex items-center justify-center w-16 bg-black rounded-r-lg h-14">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        name="search-button-svg"
                      >
                        <path
                          d="M16.5284 15.2323L20.4545 19.1575L19.1574 20.4545L15.2322 16.5285C13.7717 17.6992 11.9551 18.336 10.0833 18.3334C5.52931 18.3334 1.83331 14.6374 1.83331 10.0834C1.83331 5.52937 5.52931 1.83337 10.0833 1.83337C14.6373 1.83337 18.3333 5.52937 18.3333 10.0834C18.336 11.9552 17.6992 13.7718 16.5284 15.2323ZM14.6896 14.5521C15.8529 13.3558 16.5026 11.7521 16.5 10.0834C16.5 6.53771 13.6281 3.66671 10.0833 3.66671C6.53765 3.66671 3.66665 6.53771 3.66665 10.0834C3.66665 13.6281 6.53765 16.5 10.0833 16.5C11.752 16.5027 13.3557 15.853 14.5521 14.6896L14.6896 14.5521V14.5521Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="flex items-center justify-between">
                    {filteredDomainList?.map((item, index) => {
                      return (
                        windowSize.width < 900 ?
                          index < 3 &&
                          <div className="flex items-center mt-10" key={index}>
                            <p className="mr-2 text-lg font-bold text-selectboxText">{item.extension}</p>
                            <p
                              className="font-medium">{calculatePrice(item.domain_product_prices[0].content.register_price, item.currency)[0]}.<sup
                              className="font-normal">{calculatePrice(item.domain_product_prices[0].content.register_price)[1]} {currencyType}</sup>
                            </p>
                          </div>
                          :
                          index < 4 &&
                          <div className="flex items-center mt-10" key={index}>
                            <p className="mr-2 text-lg font-bold text-selectboxText">{item.extension}</p>
                            <p
                              className="font-medium">{calculatePrice(item.domain_product_prices[0].content.register_price, item.currency)[0]}.<sup
                              className="font-normal">{calculatePrice(item.domain_product_prices[0].content.register_price)[1]} {currencyType}</sup>
                            </p>
                          </div>
                      );
                    })
                    }
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeDomainSearch;
