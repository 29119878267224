import React from "react";
import Image from "next/image";
import HomeMakdosBlogCard from "./HomeMakdosBlogCard";
import { useTranslation } from "next-i18next";

function HomeMakdosBlog({ data }) {
  const { t } = useTranslation(["common", "homapage"]);

  return (
    <div className="relative w-full p-4 text-center bg-white lg:p-20">
      <span className="relative mb-8 text-4xl font-semibold text-after-shadow lg:text-5xl">Makdos Blog</span>
      <div className="container z-10 grid grid-cols-4 gap-10 mx-auto mt-8 blogCards">

        {data?.results.map((item, index) => {
          return (
            <HomeMakdosBlogCard key={index} data={item} />
          );
        })

        }

      </div>

      <a href="https://makdos.blog/" target="_blank" title="Makdos Blog"
         className="container grid grid-cols-12 p-4 mx-auto my-auto mt-16 duration-300 border rounded-md cursor-pointer hover:shadow-md border-makdosSecondaryGray">
        <div className="col-span-12 blogLogo lg:col-span-2">
          <Image src="/blogLogo.png" alt="makdos-blog" className="w-full h-full" title="Makdos Blog" width="198" height="51" />
        </div>
        <div className="col-span-12 mx-auto blogLogo lg:col-span-8 lg:text-left">
          <h4 className="text-lg font-semibold">{t("homepage:makdosBlogTitle")}</h4>
          <p className="my-3 text-sm text-cardText lg:my-0">{t("homepage:makdosBlogDescription")}</p>
        </div>
        <div className="col-span-12 blogLogo lg:col-span-2 lg:text-left ">
          <p className="text-xl font-semibold text-cardText">{t("homepage:makdosBlogSlogan")}</p>
        </div>
      </a>

    </div>
  );
}

export default HomeMakdosBlog;
