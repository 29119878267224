import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";

function HomePageSlider() {
  const [currentSlide, setCurrentSlide] = useState({ next: 0 });
  const { t } = useTranslation("slider");
  const router = useRouter();
  const settings = {
    dots: true,
    autoplay: false,
    infinite: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (prev, next) => {
      setCurrentSlide({ next });
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    appendDots: dots => (
      <div aria-hidden="true" className="">
        <ul className="flex w-full h-8 slick-dots lg:pl-14"> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div aria-hidden="true">
        {currentSlide?.next === i ?
          <div className="w-32 h-5 p-3 mb-5">
            <svg width="30" height="4" viewBox="0 0 30 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="30" height="4" fill="#FFDD00" />
            </svg>
          </div>

          :
          <div className="w-32 h-5 p-3 mb-5">
            <svg width="30" height="4" viewBox="0 0 30 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="30" height="4" fill="#F1F1F1" />
            </svg>
          </div>

        }

      </div>
    )

  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (

      <svg aria-hidden="true" className={` absolute right-0 top-[40%] w-[70px] h-[70px] mr-[22px] cursor-pointer z-10 hidden lg:block`} onClick={onClick}
        width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M35.1254 32L21.9254 18.8L25.696 15.0294L42.6667 32L25.696 48.9707L21.9254 45.2L35.1254 32Z"
          fill="#D7D7D7" />
      </svg>

    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <svg aria-hidden="true" className={` absolute left-0 top-[40%] w-[70px] h-[70px] cursor-pointer z-10 hidden lg:block`} onClick={onClick} style={{ width: "70px", height: "70px", zIndex: "10" }} width="64"
        height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M28.8747 32L42.0747 45.2L38.304 48.9706L21.3333 32L38.304 15.0293L42.0747 18.8L28.8747 32Z"
          fill="#D7D7D7" />
      </svg>

    );
  }

  useEffect(() => {

    const allAriaHidden = document.querySelectorAll('.slick-slide');
    const allClonedAriaHidden = document.querySelectorAll('.slick-cloned');
    allAriaHidden.forEach((item) => {
      item.removeAttribute('aria-hidden');
    });
    allClonedAriaHidden.forEach((item) => {
      item.removeAttribute('aria-hidden');
    });
  }, [])


  return (
    <div className="container h-auto mx-auto lg:h-600 " aria-hidden="false"  >
      <Slider  {...settings}>
        <div className="px-4 slick-slide" >
          <div className="w-full py-8 bg-cover lg:py-20 " >
            <div className="grid grid-cols-2 gap-5 lg:gap-0">
              <div className="col-span-2 lg:ml-6 lg:pl-10 lg:col-span-1 lg:py-11 ">
                <h3 className="mb-2 text-3xl">{t('slider:slide1title')}</h3>
                <span className="relative mb-8 text-4xl font-semibold text-after-shadow lg:text-6xl">{t('slider:slide1title2')}</span>
                <p className="mt-6 mr-2 text-xl">{t('slider:slide1description')}</p>
                <div className="flex flex-col mt-5 text-lg">
                  <div className="flex  gap-2 my-1.5">
                    <div>
                      <i className="text-2xl text-green-500 ri-checkbox-circle-fill"></i>
                    </div>
                    <span>{t('slider:slide1Item1')}</span>
                  </div>
                  <div className="flex  gap-2 my-1.5">
                    <div>
                      <i className="text-2xl text-green-500 ri-checkbox-circle-fill"></i>
                    </div>
                    <span>{t('slider:slide1Item2')}</span>
                  </div>
                  <div className="flex  gap-2 my-1.5">
                    <div>
                      <i className="text-2xl text-green-500 ri-checkbox-circle-fill"></i>
                    </div>
                    <span>{t('slider:slide1Item3')}</span>
                  </div>
                </div>
                {/* <div className="gap-6 lg:flex">
                  <button className="flex items-center justify-center px-5 py-2.5 mt-5 rounded-md bg-makdosYellow w-full lg:w-auto">
                    <i className="mt-1 mr-2 text-2xl ri-app-store-line"></i>
                    {t('slider:slide1Button1')}
                  </button>
                  <button className="flex items-center px-5 py-2.5  justify-center mt-5 rounded-md bg-makdosYellow w-full lg:w-auto">
                    <i className="mt-1 mr-2 text-2xl ri-google-play-line"></i>

                    {t('slider:slide1Button2')}
                  </button>
                </div> */}
              </div>
              <div className="col-span-2 lg:col-span-1 lg:w-[538px] lg:h-[422px]">
                <Image priority src="/slide1image.png" className="w-[538px] h-[422px] " alt="mobile-app" title="Mobile App" width="632" height="500" />
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 slick-slide "  >
          <div className="w-full py-8 bg-cover lg:py-20 " >
            <div className="grid grid-cols-2 gap-5 lg:gap-0">
              <div className="col-span-2 lg:ml-6 lg:pl-10 lg:col-span-1 lg:py-11">
                <h3 className="mb-2 text-3xl">{t('slide2title')}</h3>
                <span className="relative w-full mb-8 text-4xl font-semibold text-after-shadow lg:text-6xl">{t('slide2title2')}</span>
                <p className="mt-6 mr-2 text-xl">{t('slide2description')}</p>
                <div className="flex flex-col mt-5 text-lg">
                  <div className="flex flex-row gap-4 my-1.5">
                    <i className="text-2xl text-green-500 ri-checkbox-circle-fill"></i>
                    <span>{t('slide2Item1')}</span>
                  </div>
                  <div className="flex flex-row gap-4 my-1.5">
                    <i className="text-2xl text-green-500 ri-checkbox-circle-fill"></i>
                    <span>{t('slide2Item2')}</span>
                  </div>
                  <div className="flex flex-row gap-4 my-1.5">
                    <i className="text-2xl text-green-500 ri-checkbox-circle-fill"></i>
                    <span>{t('slide2Item3')}</span>
                  </div>
                </div>
                <a href="https://panel.makdos.com" target="_blank">
                  <button className="flex items-center lg:w-auto w-full justify-center px-5 py-2.5 mt-5 rounded-md bg-makdosYellow">
                    {t('slide2Button1')}
                    <i className="ml-2 text-xl ri-arrow-right-line"></i>
                  </button>
                </a>
              </div>
              <div className="col-span-2 lg:col-span-1 ">
                <Image src="/slide2image.png" alt="panel" title="Panel" width="538" height="422" />
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 slick-slide" >
          <div className="w-full py-8 bg-cover lg:py-20 " >
            <div className="grid grid-cols-2 gap-5 lg:gap-0">
              <div className="col-span-2 lg:ml-6 lg:pl-10 lg:col-span-1 lg:py-11 lg:mt-14">
                <h3 className="mb-2 text-3xl"> {t('slide3title')}</h3>
                <span
                  className="relative mb-8 text-4xl font-semibold text-after-shadow lg:text-6xl">{t('slide3title2')}</span>
                <p className="mt-6 mr-2 text-xl">{t('slide3description')}</p>
                <Link href={t("nav:serverservices")}>
                  <a>
                    <button className="flex items-center lg:w-auto w-full justify-center px-5 py-2.5 mt-5 rounded-md bg-makdosYellow">
                      {t('slide3Button1')}
                      <i className="ml-2 text-xl ri-arrow-right-line"></i>

                    </button>
                  </a>

                </Link>
              </div>
              <div className="col-span-2 lg:col-span-1 ">
                {router.locale === "tr" ?
                  <Image src="/slide3image.png" alt="servers" title="Servers" width="538" height="422" />
                  :
                  <Image src="/slide3imageEN.png" alt="servers" title="Servers" width="538" height="422" />
                }
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 slick-slide" >
          <div className="w-full py-8 bg-cover lg:py-20 " >
            <div className="grid grid-cols-2 gap-5 lg:gap-0">
              <div className="col-span-2 lg:ml-6 lg:pl-10 lg:col-span-1 lg:py-11 lg:mt-14">
                <h3 className="mb-2 text-3xl">{t('slide4title')}</h3>
                <span
                  className="relative mb-8 text-4xl font-semibold text-after-shadow lg:text-6xl">{t('slide4title2')}</span>
                <p className="mt-6 mr-2 text-xl">{t('slide4description')}</p>
                <Link href={t("nav:serverservices")}>
                  <a>
                    <button className="flex items-center lg:w-auto w-full justify-center px-5 py-2.5 mt-5 rounded-md bg-makdosYellow">
                      {t('slide4Button1')}
                      <i className="ml-2 text-xl ri-arrow-right-line"></i>
                    </button>
                  </a>
                </Link>
              </div>
              <div className="col-span-2 lg:col-span-1 ">
                <Image src="/slide4image.png" alt="support" title="Support" width="538" height="422" />
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 slick-slide" >
          <div className="w-full py-8 bg-cover lg:py-20 " >
            <div className="grid grid-cols-2 gap-5 lg:gap-0">
              <div className="col-span-2 lg:ml-6 lg:pl-10 lg:col-span-1 lg:py-28">

                <h3 className="mb-2 text-3xl">{t('slide5title')}</h3>
                <span
                  className="relative mb-8 text-4xl font-semibold text-after-shadow lg:text-6xl">{t('slide5title2')}</span>
                <p className="mt-6 mr-2 text-xl">{t('slide5description')}</p>

                <Link href={t("nav:corporateemail")}>
                  <a>
                    <button className="flex items-center lg:w-auto w-full justify-center px-5 py-2.5 mt-5 rounded-md bg-makdosYellow">
                      {t('slide5Button1')}
                      <i className="ml-2 text-xl ri-arrow-right-line"></i>
                    </button>
                  </a>
                </Link>

              </div>
              <div className="col-span-2 lg:col-span-1 ">
                {currentSlide?.next === 4 &&
                  <Image src="/slide5image.png" alt="mail" title="Mail" width="538" height="422" />
                }
              </div>
            </div>
          </div>
        </div>

      </Slider>
    </div>

  );
}

export default HomePageSlider;


