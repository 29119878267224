import { googleEventConfiguration } from "helpers/googleEvents";
import { changeCurrency } from "helpers/helpersFunc";
import { isEmpty } from "lodash";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import React from "react";
import { useConfigurationStore } from "store/ConfigurationStore";
import { useGlobalStore } from "store/GlobalStore";
import { useHostingDomainConfigurationStore } from "store/HostingDomainConfigurationStore";

function HomePackagesCard({ data, discountAlert, isMonthly }) {
  const [currencyType, currencyRates] = useGlobalStore((state) => [state.currencyType, state.currencyRates]);
  const [setSelectedServer, clearAllConfigurations, setConfigurationType] = useConfigurationStore((((state) => [state.setSelectedServer, state.clearAllConfigurations, state.setConfigurationType])));
  const [domainComponentValues, clearAllDomainConfigurations] = useHostingDomainConfigurationStore(((state) => [state.domainComponentValues, state.clearAllConfigurations]));

  const router = useRouter();
  const { t } = useTranslation("common");

  const calculatePrice = (price, currency) => {
    let newPrice = changeCurrency(currencyRates, currencyType, parseFloat((price)?.toFixed(2)), currency);
    let splitedPrice = newPrice.split(".");
    return splitedPrice;

  };


  const setId = () => {
    googleEventConfiguration(data, data.one_month_price, data.currency, "homepage");
    clearAllConfigurations();
    clearAllDomainConfigurations();
    setSelectedServer(data.id);
    setConfigurationType(data.product_type_id === 1 ? "hosting" : "server");
    router.push(t("configuration"));
  };

  return (
    <div onClick={setId}
      className="mb-6 text-left transition duration-500 shadow-lg cursor-pointer card group lg:transform lg:hover:scale-110 hover:shadow-2xl">
      {discountAlert &&
        <div className="absolute bg-transparent discount -top-3">
          <div className="w-full p-0 px-2 py-1 m-0 bg-red-600 rounded-2xl ">
            <span className="px-2 py-1 text-sm font-bold text-textWhite">{discountAlert}</span>
          </div>
          <div className="absolute w-full -bottom-1.5">
            <svg className="p-0 mx-auto " width="10" height="8" viewBox="0 0 10 8" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.77771 7.03783C5.37747 7.533 4.62253 7.533 4.22228 7.03783L0.254217 2.12862C-0.274357 1.47468 0.191081 0.5 1.03193 0.5L8.96807 0.500001C9.80892 0.500001 10.2744 1.47468 9.74578 2.12862L5.77771 7.03783Z"
                fill="#dc2626" />
            </svg>
          </div>
        </div>
      }
      <div
        className="p-5 transition-colors duration-300 rounded-t-lg title bg-makdosYellow group-hover:bg-makdosLaci group-hover:text-makdosYellow">
        <p className="text-xl font-bold">{data.product_name}</p>
      </div>
      <div className="px-4 py-10 cardContent ">
        <div className="grid grid-cols-5 mb-4 ">
          <div className="w-full col-span-3 leftContent">
            <span className="text-xs text-textGray">CPU Max</span>
            <p className="text-xl font-semibold">{data.cpu}</p>
          </div>
          <div className="w-full col-span-2 rightContent">
            <span className="text-xs text-textGray">Disk</span>
            <p
              className="text-xl font-semibold">{isEmpty(data.disk) ? (data?.product_group_id === 63 ? t("unlimited") : t("optional")) : (data.disk.disk_amount > 1 ? data.disk.disk_amount + "x" + data.disk.disk_size + " " + data.disk.disk_unit : data.disk.disk_size + " " + data.disk.disk_unit)}</p>
          </div>
        </div>
        <div className="grid grid-cols-5 mb-4">
          <div className="w-full col-span-3 leftContent">
            <span className="text-xs text-textGray">{t('bandwidth')}</span>
            <p className="text-xl font-semibold">{data?.bandwidth ? data?.bandwidth : t("unlimited")}</p>
          </div>
          <div className="w-full col-span-2 rightContent">
            {data.product_type_id === 1 ?
              <>
                <span className="text-xs text-textGray">{t('subDomain')}</span>
                <p className="text-xl font-semibold">{data?.domain} {t('unit')}</p>
              </>
              :
              <>
                <span className="text-xs text-textGray">Ram</span>
                <p className="text-xl font-semibold">{data?.ram}</p>
              </>
            }

          </div>
        </div>
        <div className="grid grid-cols-5 mb-4">
          <div className="w-full col-span-3 leftContent">
            {data.product_type_id === 1 ?
              <>
                <span className="text-xs text-textGray">Alt Alanadı</span>
                <p
                  className="text-xl font-semibold">{data?.subdomain != "unlimited" ? data?.subdomain + " Adet" : "Limitsiz"}</p>
              </>
              :
              <>
                <span className="text-xs text-textGray">Network</span>
                <p className="text-xl font-semibold">{data?.network != "unlimited" ? data?.network : "Limitsiz"}</p>
              </>

            }

          </div>
          <div className="w-full col-span-2 rightContent">

            {data.product_type_id === 1 ?
              <>
                <span className="text-xs text-textGray">E-Posta Hesabı</span>
                <p
                  className="text-xl font-semibold">{data?.email != "unlimited" ? data?.email + " Adet" : "Limitsiz"}</p>
              </>
              :
              <>
                <span className="text-xs text-textGray">IP</span>
                <p
                  className="text-xl font-semibold">{data?.ip_address != "unlimited" ? (data?.ip_address === "/32" ?( "1 " + t('unit')) : data?.ip_address) : "Limitsiz"}</p>
              </>

            }

          </div>
        </div>
        {data.product_type_id === 1 &&
          <div className="grid grid-cols-5 mb-4">
            <div className="w-full col-span-3 leftContent">
              <span className="text-xs text-textGray">MySQL</span>
              <p
                className="text-xl font-semibold">{data?.mysql != "unlimited" ? data?.mysql + " Adet" : "Limitsiz"} </p>
            </div>
            <div className="w-full col-span-2 rightContent">
              <span className="text-xs text-textGray">MySQL Boyutu</span>
              <p className="text-xl font-semibold">{data?.mysql_size === "unlimited" ? t("unlimited") : data.mysql_size} {!data?.mysql_size && t("unlimited")}</p>
            </div>
          </div>
        }

      </div>
      <div className="p-4 rounded-b-lg cardFooter bg-makdosLaci">
        <div className="footerPrice">
          {/* <span className="line-through text-textGray">50.00 TL</span> */}
          <div className="flex">
            <p
              className="text-4xl font-bold text-makdosYellow"> {calculatePrice(isMonthly ? data.one_month_price : data.one_year_price, data.currency)[0]}.</p>
            <div className="flex flex-col ml-1">
              <span
                className="p-0 m-0 text-sm text-makdosYellow">{calculatePrice(isMonthly ? data.one_month_price : data.one_year_price, data.currency)[1]} {currencyType}</span>
              <span className="p-0 m-0 text-sm text-makdosYellow">{isMonthly ? t('monthly') : t('yearly')} + Extra</span>
            </div>
          </div>
        </div>
        <button onClick={setId} className="w-full p-2 mt-4 mb-1 bg-white rounded-lg">+ {t('configure')}</button>
      </div>
    </div>
  );
}

export default HomePackagesCard;
