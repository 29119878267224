import create from "zustand";
import { devtools } from "zustand/middleware";

export const useHostingDomainConfigurationStore = create(devtools((set, get) => ({
  domainConfigurationTotal: [],
  domainConfiguration: [],
  domainComponentValues: [],
  addDomainComponentItem: (id, CompName, price, count, componentId, selectBoxName, configurationName, compOrder, raid, displayName, details) => {
    if (get().domainComponentValues.length === 0) {
      set((state) => ({
        domainComponentValues: [
          ...get().domainComponentValues,
          {
            id: id,
            CompName: CompName,
            price: price,
            count: count,
            componentId: componentId,
            selectBoxName: selectBoxName,
            configurationName: configurationName,
            compOrder: compOrder,
            raid: raid,
            displayName: displayName,
            details: details
          }
        ]
      }));
      get().domainComponentValues.sort((a, b) => a.compOrder - b.compOrder);
    } else {
      let isExist = false;
      get().domainComponentValues.map((c, i) => {
        if ((c.CompName === CompName) && (c.selectBoxName === selectBoxName)) isExist = true;
        if (i + 1 === get().domainComponentValues.length && isExist === false) {
          set((state) => ({
            domainComponentValues: [
              ...get().domainComponentValues,
              {
                id: id,
                CompName: CompName,
                price: price,
                count: count,
                componentId: componentId,
                selectBoxName: selectBoxName,
                configurationName: configurationName,
                compOrder: compOrder,
                raid: raid,
                displayName: displayName,
                details: details
              }
            ]
          }));
          get().domainComponentValues.sort((a, b) => a.compOrder - b.compOrder);
        }
      });
    }
    get().addToLocalStorage();
  },
  addDomainComponent: (id, name, group, productId, productName) => {
    if (get().domainConfiguration.length === 0) {
      set((state) => ({
        domainConfiguration: [
          ...get().domainConfiguration,
          {
            id: id,
            name: name,
            group: group,
            productId: productId,
            productName: productName
          }
        ]
      }));
    } else {
      let isExist = false;
      get().domainConfiguration.map((c, i) => {
        if (c.id === id) isExist = true;
        if (i + 1 === get().domainConfiguration.length && isExist === false) {
          set((state) => ({
            domainConfiguration: [
              ...get().domainConfiguration,
              {
                id: id,
                name: name,
                group: group,
                productId: productId,
                productName: productName
              }
            ]
          }));
        }
      });
    }
    get().addToLocalStorage();
  },
  addDomainProduct: (id, name) => {
    if (get().domainConfigurationTotal.length === 0) {
      set((state) => ({
        domainConfigurationTotal: [
          ...get().domainConfigurationTotal,
          {
            id: id,
            name: name

          }
        ]
      }));
    } else {
      let isExist = false;
      get().domainConfigurationTotal.map((c, i) => {
        if (c.id === id) isExist = true;
        if (i + 1 === get().domainConfigurationTotal.length && isExist === false) {
          set((state) => ({
            domainConfigurationTotal: [
              ...get().domainConfigurationTotal,
              {
                id: id,
                name: name
              }
            ]
          }));
        }
      });
    }
    get().addToLocalStorage();
  },

  clearDomainComponentValues: () => {
    set((state) => ({
      domainComponentValues: []
    }));
    get().addToLocalStorage();
  },
  removeDomainComponentItem: (name) => {
    set((state) => ({
      domainComponentValues: get().domainComponentValues.filter((c) => c.CompName !== name)
    }));
    get().addToLocalStorage();
  },
  removeDomainComponent: (id) => {
    set((state) => ({
      domainConfiguration: get().domainConfiguration.filter((c) => c.id !== id)
    }));
    get().domainComponentValues.map((x) => {
      if (x.componentId === id) get().removeDomainComponentItem(x.CompName);
    });
    get().addToLocalStorage();

  },
  removeProduct: (id) => {
    set((state) => ({
      domainConfigurationTotal: get().domainConfigurationTotal.filter((c) => c.id !== id)
    }));
    get().domainConfiguration.map((x) => {
      if (x.productId === id) get().removeDomainComponent(x.id);
    });
    get().addToLocalStorage();
  },
  clearAllConfigurations: () => {
    set((state) => ({
      domainConfigurationTotal: [],
      domainConfiguration: [],
      domainComponentValues: []
    }));
    get().addToLocalStorage();
  },

  domainSelectedYear: {},
  setDomainSelectedYear: (year) => {
    set((state) => ({
      domainSelectedYear: year
    }));
    get().addToLocalStorage();

  },

  addToLocalStorage: () => {
    localStorage.removeItem("__domainProducts");
    localStorage.removeItem("__domainConfiguration");
    localStorage.removeItem("__domainComponentValues");

    localStorage.setItem("__domainProducts", JSON.stringify(get().domainConfigurationTotal));
    localStorage.setItem("__domainConfiguration", JSON.stringify(get().domainConfiguration));
    localStorage.setItem("__domainComponentValues", JSON.stringify(get().domainComponentValues));
    localStorage.setItem("__domainSelectedYear", JSON.stringify(get().domainSelectedYear));

  },

  checkLocalStorage: () => {
    if (typeof localStorage.getItem("__domainProducts") !== "undefined" && localStorage.getItem("__domainProducts") !== null && localStorage.getItem("__domainProducts") !== "")
      set((state) => ({ domainConfigurationTotal: JSON.parse(localStorage.getItem("__domainProducts")) }));
    if (typeof localStorage.getItem("__domainConfiguration") !== "undefined" && localStorage.getItem("__domainConfiguration") !== null && localStorage.getItem("__domainConfiguration") !== "")
      set((state) => ({ domainConfiguration: JSON.parse(localStorage.getItem("__domainConfiguration")) }));
    if (typeof localStorage.getItem("__domainComponentValues") !== "undefined" && localStorage.getItem("__domainComponentValues") !== null && localStorage.getItem("__domainComponentValues") !== "")
      set((state) => ({ domainComponentValues: JSON.parse(localStorage.getItem("__domainComponentValues")) }));

    if (typeof localStorage.getItem("__domainSelectedYear") !== "undefined" && localStorage.getItem("__domainSelectedYear") !== null && localStorage.getItem("__domainSelectedYear") !== "")
      localStorage.getItem("__domainSelectedYear") !== "0" && localStorage.getItem("__domainSelectedYear") !== "undefined" && set((state) => ({ domainSelectedYear: JSON.parse(localStorage.getItem("__domainSelectedYear")) }));
  }
})));